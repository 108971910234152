$textSizes: 9, 10, 12, 13;
@each $size in $textSizes {
    .f#{$size} {
        font-size: #{$size}px;
    }
}

.f11 {
    font-size: 85%;
}

.button-free-login {
    width: 360px;
    height: 60px;
    line-height: 60px;
    font-size: 26px;
    background: #032E49;
    color: #fff;
    border: none;
    border-radius: 3px;
}

div.notice {
    text-align: center;
    margin-top: 10px;
}

#registration {
    line-height: 1.5 !important;
}

.bt_login {
    text-align: center;
    width: 300px;
    margin: 30px auto;

    a {
        border-radius: 5px;
        padding: 15px;
        font-size: 14px;
        display: block;
        border-width: 1px;
    }
}

div.small_button {
    text-align: center;

    .login {
        width: 180px;
        height: 60px;
        line-height: 60px;
        font-size: 26px;
        background: #032E49;
        color: #fff;
        border: none;
        border-radius: 3px;
    }
}


span.required {
    font-weight: bold;
}

p.err_message {
    color: red;
    font-weight: bold;
    text-align: center;
    font-size: 18px;
}

.innerBox table.form_check {
    th {
        width: 40%;
        padding-right: 20px;
    }

    &.confirm-send-contact {
        width: 100%;
        th.parent{
            font-size: 15px;
            text-align: right;
            padding: 10px 5px;
            white-space: nowrap;
            width: 10em;
        }
        td.parent {
            padding: 10px 5px;
        }
    }
}


.page-contact {
    #registration {
        line-height: unset !important;
    }
    .required {
        font-weight: normal;
    }
}

ul.actionLink {
    width: 715px;
    margin: 0 auto;
}

.page-auth-free_login{
    .innerBox {
        padding-bottom: 80px;
    }

    #footer{
        line-height: 1.5;
        img{
            vertical-align: bottom;
        }
    }
}

.page-web-faq-route, .page-web-rules-route, .page-web-contact, .page-web-payment-route, .page-web-company-route {
    #footer{
        .copyArea {
            p {
                font-size: 16px;
            }
        }
    }
}

/* ------------------------------------------------------------------
	FootArea
-------------------------------------------------------------------*/
#footer.user-footer {
    height:70px;
    background:#002a3b;
    padding: unset;

    .footInner {
        width:736px;
        margin:0 auto;
        font-size:14px;

        li {
            line-height:70px;
            float:left;
            padding-right:30px;

            a {
                color:#fff;
            }

        }
    }
}
